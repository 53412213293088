import { color } from 'console-log-colors'
import { logFactory } from '~/util/logger'

const logger = logFactory('env', color.bold.redBright)

/**
 * A Proxy around `import.meta.env` that allows
 * you to access variables without remembering to
 * prefix the variable names with "VITE_".
 * However, you can still reference them that way
 * if you choose. Additionally, you can expose helpers
 * on this class to simplify using variables.
 *
 * Some Examples:
 * --------------
 * env.name === import.meta.env.VITE_NAME
 * env.VITE_NAME === import.meta.env.VITE_NAME
 * env.production === (import.meta.env.NODE_ENV === 'production')
 */
export class EnvWrapper {
  // The environment we are currently running in.
  environment = import.meta.env.VITE_ENV
  // The env file that was used to start the app.
  nodeEnvironment = import.meta.env.MODE

  // Specific environment flags...
  production = import.meta.env.VITE_ENV === 'production'
  development = import.meta.env.VITE_ENV === 'development'
  test = import.meta.env.VITE_ENV === 'test'

  version = import.meta.env.VITE_VERSION

  // Whether or not to perform verbose logging.
  verbose = import.meta.env.VITE_VERBOSE === 'true'

  // Whether or not to run against mock APIs.
  mock = import.meta.env.VITE_MOCK_APIS === 'true'

  // The name of the app.
  appName = import.meta.env.VITE_NAME
  appURL = import.meta.env.VITE_APP_BASE_URL || ''
  baseURL = import.meta.env.BASE_URL || '/'
  // The base URL for API calls.
  apiURL = import.meta.env.VITE_API_BASE_URL || ''
  // The API key for accessing the UnitMap API
  unitMapAPIKey = import.meta.env.VITE_UNIT_MAP_KEY
  // The key used to identify PowerPro images in Cloudinary.
  cloudinaryAPIKey = import.meta.env.VITE_CLOUDINARY_KEY || 'powerpro'
  // The guest card uuid to use in dev if none is specified.
  guestCardUUID = import.meta.env.VITE_GUEST_CARD_UUID
  // The tracking id to use for Google Analytics tracking.
  gaTrackingId = import.meta.env.VITE_GA_TRACKING_ID
  // The URL to the app's public folder.
  publicFolder = import.meta.env.PUBLIC_URL
  /** Google Maps API URL */
  googleMapsApi = import.meta.env.VITE_GOOGLE_MAPS_API
  /** Google maps api key */
  googleMapsApiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY

  personaTemplateId = import.meta.env.VITE_PERSONA_TEMPLATE_ID
  personaEnvironmentId = import.meta.env.VITE_PERSONA_ENVIRONMENT_ID

  /**
   * Convert a relative file path in the public folder to
   * an absolute path.
   */
  publicFileURL(filePath: string) {
    return env.publicFolder + '/' + filePath
  }

  /**
   * Log the environment variables.
   */
  log() {
    // Always log the software version.
    logger.log(`version ${this.version}`)
    if (!this.production) {
      logger.log('environment:', this.environment)
      for (let key in this) {
        const blacklist = ['log', 'environment', 'graphAPIKey', 'version']
        if (blacklist.indexOf(key) === -1) {
          logger.log(`${key}:`, this[key])
        }
      }
    }
  }
}

export const env = new EnvWrapper()
