import React from 'react'
import PropTypes from 'prop-types'
import { color } from 'console-log-colors'

import { combineClasses, logFactory } from '~/util'
import { MediaItemPropType } from '~/store'
import { env } from '~/service'

import { Image } from '../Image'
import { Modal } from '../../modal'
import { Action } from '../../buttons'

import styles from './ImageLayout.module.scss'

const logger = logFactory('ImageLayout', color.bold.greenBright)

/**
 * Get a random number of items from the front of the given list.
 * @param {*[]} items - The list to pull from.
 * @param {number} max - The maximum number of items to pull.
 * @returns {*[]}
 */
export function randomNumberOfItems(items, max = 3) {
  const count = Math.round(Math.random() * (max - 1)) + 1
  return items?.slice(0, Math.min(count, items.length))
}

/**
 * Break the give list of items into a multidimensional
 * array of those items where each outer row contains
 * a random number of the input items.
 *
 * @param {*[]} items - The items to break into randomized rows.
 * @param {*[][]} [rows] - The output list to add rows to.
 * @param {number} [max] - The maximum number if items in each row.
 * @returns {*[][]}
 */
export function makeRows(items, rows = [], max = 3) {
  const row = randomNumberOfItems(items, max)
  const nextItems = items.slice(row.length)

  rows.push(row)

  if (nextItems.length > 0) return makeRows(nextItems, rows)
  else return rows
}

/**
 * `<ImageLayout>` displays a list of image URLs
 * and/or media items in a grid-like layout with
 * a varying number of images in each row.
 *
 * @param {object} props
 * @param {string} [props.className]
 * @param {(string|object)[]} [props.images] - The list of image URLs and/or media items
 *   to show.
 * @param {number} [props.max] - The maximum number of items per row.
 */
export function ImageLayout({ className, images = [], max = 3, ...rest }) {
  const [selected, setSelected] = React.useState()
  const rows = React.useMemo(() => makeRows(images, [], max), []) // eslint-disable-line react-hooks/exhaustive-deps

  const onShowImage = (image) => {
    setSelected(image)
  }

  const onHideImage = () => {
    setSelected(null)
  }

  return (
    <div
      data-testid="ImageLayout"
      className={combineClasses(styles.ImageLayout, className)}
      {...rest}
    >
      {rows.map((row, i) => (
        <div className={styles.row} key={i}>
          {row.map((image, j) => (
            <Action
              key={j}
              data-testid="thumbnailButton"
              button
              unstyled
              style={{
                width: `${100 / row.length}%`,
              }}
              onClick={() => onShowImage(image)}
            >
              <Image
                data-testid="thumbnail"
                className={styles.Image}
                src={typeof image === 'string' ? image : undefined}
                mediaItem={typeof image === 'object' ? image : undefined}
                display="inline"
                fit="cover"
                responsive
                lazy={!env.test}
                width="100%"
                height="100%"
                onError={(e) => {
                  logger.error(['ERROR', color.red], e)
                  logger.log(['IMAGE', color.red], image)
                }}
              />
            </Action>
          ))}
        </div>
      ))}

      {selected && (
        <Modal
          data-testid="Modal"
          label="Image Gallery"
          isOpen={!!selected}
          setIsOpen={onHideImage}
        >
          <Image
            data-testid="modalImage"
            className={styles.modalImage}
            src={typeof selected === 'string' ? selected : undefined}
            mediaItem={typeof selected === 'object' ? selected : undefined}
            fit={'scale-down'}
            transparent={true}
          />
        </Modal>
      )}
    </div>
  )
}

ImageLayout.propTypes = {
  /**
   * The list of image URLs and/or media items to display.
   */
  images: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, MediaItemPropType])
  ),
}
