import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { RouterProps } from 'react-router'
import { color } from 'console-log-colors'

import { logFactory } from '~/util'
import { getRoute } from '../routes'
import { ID, usePageTracking } from '~/service'

import { PageLayout } from './PageLayout'

// PAGES
import { NotFound } from '../not-found'
import { VerifyIdentityConnected } from '../verify-identity'

const logger = logFactory('History', color.bold.magenta)

/**
 * Dynamically import a module with a default export and return the default
 * export.
 */
function useModule<DefaultExport>(
  getModule: () => Promise<{ default: DefaultExport }>
) {
  const [Components, setComponents] = React.useState<DefaultExport>()

  React.useEffect(
    () => {
      getModule().then((module) => {
        setComponents(module.default)
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return Components
}

type TourToGoPages =
  typeof import('~/pages/tour-to-go/tour-to-go.module')['default']
interface TourToGoProps {
  page: keyof TourToGoPages
}

function TourToGo({ page, ...rest }: TourToGoProps) {
  const Components = useModule<TourToGoPages>(
    () => import('~/pages/tour-to-go/tour-to-go.module')
  )

  if (!Components) {
    return null
  } else {
    const C = Components[page]
    return <C {...rest} />
  }
}

type SelfTourPages =
  typeof import('~/pages/self-tour/self-tour.module')['default']
interface SelfTourProps {
  page: keyof SelfTourPages
}

function SelfTour({ page, ...rest }: SelfTourProps) {
  const Components = useModule<SelfTourPages>(
    () => import('~/pages/self-tour/self-tour.module')
  )

  if (!Components) {
    return null
  } else {
    const C = Components[page]
    return <C {...rest} />
  }
}

/**
 * Wraps the React Router in order to provide logging
 * of page routing. Note that we extend Router (as opposed
 * to BrowserRouter) so we can pass a history object of
 * our choosing to the router. This allows us to pass
 * an in memory `pushState` implementation during testing.
 */
export class DebugRouter extends Router {
  constructor(props: RouterProps) {
    super(props)

    logger.log('init:', JSON.stringify(props.history, null, 2))

    props.history.listen((location, action) => {
      logger.log(
        `${action} ${location.pathname}${location.search}${location.hash}`,
        'history:',
        JSON.stringify(props.history, null, 2)
      )
    })
  }
}

interface RouteHandlerProps {
  trackingId?: string
  trackingOptions?: Record<string, any>
  guestCardId: ID
  guestCardUuid: ID
  communityId: ID
  communityName: string
}

/**
 * Configure the URL to page component routing.
 */
export function RouteHandler({
  trackingId,
  trackingOptions,
  guestCardId,
  guestCardUuid,
  communityId,
  communityName,
}: RouteHandlerProps) {
  usePageTracking(
    guestCardId,
    guestCardUuid,
    communityId,
    communityName,
    trackingId,
    trackingOptions
  )

  return (
    <PageLayout>
      <Switch>
        <Route
          children={<TourToGo page="UnitDetails" />}
          path={[getRoute('UNIT_DETAIL'), getRoute('UNIT_DETAIL_LEGACY')]}
        />
        <Route
          children={<TourToGo page="Bedrooms" />}
          path={getRoute('BEDROOMS')}
        />
        <Route
          children={<TourToGo page="Occupants" />}
          path={getRoute('OCCUPANTS')}
        />
        <Route
          children={<TourToGo page="Favorites" />}
          path={[
            getRoute('INTERESTED_UNITS'),
            getRoute('INTERESTED_UNITS_LEGACY'),
          ]}
        />
        <Route children={<TourToGo page="Rent" />} path={getRoute('RENT')} />
        <Route
          children={<TourToGo page="CheckIn" />}
          path={getRoute('CHECK_IN')}
        />
        <Route
          children={<TourToGo page="QuoteDetails" />}
          path={[getRoute('QUOTE_DETAIL'), getRoute('QUOTE_DETAIL_LEGACY')]}
        />
        <Route
          children={<TourToGo page="Quotes" />}
          path={getRoute('QUOTES')}
        />
        <Route
          children={<TourToGo page="AmenityDetails" />}
          path={[getRoute('AMENITY_DETAIL'), getRoute('AMENITY_DETAIL_LEGACY')]}
        />
        <Route
          children={<TourToGo page="CommunityAmenities" />}
          path={[getRoute('AMENITIES')]}
        />
        <Route
          children={<TourToGo page="FloorPlanDetails" />}
          path={[getRoute('FLOORPLAN')]}
        />
        <Route
          children={<TourToGo page="SearchFloorPlan" />}
          path={[getRoute('SEARCHFLOORPLAN')]}
        />
        <Route
          children={<VerifyIdentityConnected />}
          path={[getRoute('VERIFY_IDENTITY')]}
        />
        <Route
          children={<SelfTour page="SelfTour" />}
          path={[getRoute('SELF_TOUR')]}
          exact
        />
        <Route
          children={<SelfTour page="SelfTourDirections" />}
          path={[getRoute('SELF_TOUR_DIRECTIONS')]}
          exact
        />
        <Route
          children={<SelfTour page="SelfTourUnitDetails" />}
          path={[getRoute('SELF_TOUR_UNIT_DETAIL')]}
        />
        <Route
          children={<SelfTour page="SelfTourAmenityDetails" />}
          path={[getRoute('SELF_TOUR_AMENITY_DETAIL')]}
        />
        <Route
          children={<TourToGo page="MyTour" />}
          path={[getRoute('HOME'), getRoute('MY_TOUR'), getRoute('ROOT')]}
          exact
        />
        <Route
          children={
            <PageLayout>
              <NotFound subtitle="We couldn't find that page." />
            </PageLayout>
          }
        />
      </Switch>
    </PageLayout>
  )
}
