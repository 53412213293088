import React from 'react'
import PropTypes from 'prop-types'

import { combineClasses } from '~/util'
import { DESTINATION_TYPES, TourPropType } from '~/store'
import {
  SubTitle,
  MiniTitle,
  Action,
  UnitCard,
  AmenityCard,
  EntranceCard,
} from '~/components'

import styles from './TourSteps.module.scss'

function formatName(prefix, name) {
  // Default to the name received from the API.
  // If the name is a number or is not passed,
  // generate a name from the number.
  return name && isNaN(Number(name)) ? name : `${prefix} ${name}`
}

/**
 * @param {object} props
 * @param {boolean} [props.linkable]
 * @param {string} [props.url]
 * @param {*} [props.children]
 */
export function StepLink({ linkable, url, children, ...rest }) {
  if (!linkable || !url) {
    return children
  } else {
    return (
      <Action
        data-testid="marker"
        // style={{width: '100%'}}
        className={styles.link}
        href={url}
        children={children}
        {...rest}
        unstyled
      />
    )
  }
}

/**
 * @param {object} props
 * @param {string} [props.className]
 * @param {object} [props.marker]
 * @param {string} [props.url]
 * @param {boolean} [props.hideStepLabels]
 * @param {boolean} [props.showUnitPrices]
 * @param {boolean} [props.showThumbnails]
 * @param {boolean} [props.isShowPriceOnModel]
 */
export function UnitStep({
  marker,
  url,
  hideStepLabels,
  showUnitPrices,
  isShowPriceOnModel,
}) {
  return (
    <StepLink linkable={true} data-type="unit" url={url}>
      <UnitCard
        className={styles.UnitCard}
        unit={marker.item}
        label={!hideStepLabels ? marker.label : null}
        showUnitPrices={showUnitPrices}
        isShowPriceOnModel={isShowPriceOnModel}
      />
    </StepLink>
  )
}

/**
 * @param {object} props
 * @param {object} [props.marker]
 * @param {string} [props.url]
 * @param {boolean} [props.hideStepLabels]
 * @param {boolean} [props.showThumbnails]
 */
export function AmenityStep({ marker, url, hideStepLabels, showThumbnails }) {
  const interesting = !!(
    marker.item.description ||
    marker.item.imageUrl ||
    marker.item.floorPlate
  )

  return (
    <StepLink linkable={interesting} data-type="amenity" url={url}>
      <AmenityCard
        className={styles.AmenityCard}
        label={!hideStepLabels ? marker.label : undefined}
        name={marker.item.name}
        imageUrl={showThumbnails ? marker.item.imageUrl : undefined}
        description={marker.item.description}
      />
    </StepLink>
  )
}

/**
 * @param {object} props
 * @param {object} [props.floorPlate]
 * @param {string} [props.name]
 * @param {object[]} [props.markers]
 * @param {object[]} [props.leasingOffices]
 * @param {boolean} [props.showUnitPrices]
 * @param {function} [props.getUnitURL]
 * @param {function} [props.getAmenityURL]
 * @param {boolean} [props.hideStepLabels]
 * @param {string} [props.className]
 * @param {string} [props.stickyStepHeaderClassName]
 * @param {boolean} [props.showThumbnails]
 * @param {boolean} [props.isShowPriceOnModel]
 *   doors
 * @param {Lock[]} [props.communityLocks]
 */
export function TourFloor({
  floorPlate,
  name = floorPlate.name,
  markers,
  leasingOffices,
  communityLocks,
  showUnitPrices,
  getUnitURL,
  getAmenityURL,
  hideStepLabels,
  className,
  stickyStepHeaderClassName,
  showThumbnails,
  isShowPriceOnModel,
}) {
  // TODO Allow passing a marker render function
  return (
    <div
      className={combineClasses(styles.TourFloor, className)}
      data-testid="TourFloor"
    >
      {name && (
        <div
          className={combineClasses(
            styles.floorHeader,
            stickyStepHeaderClassName
          )}
        >
          <SubTitle data-testid="floorTitle" className={styles.floorTitle}>
            {formatName('Floor', name)}
          </SubTitle>
        </div>
      )}
      <div className={styles.floorContent}>
        {leasingOffices?.length > 0 && (
          <EntranceCard
            data-testid="entrance"
            transparent
            className={styles.LeasingOfficeCard}
            // For the moment, we don't actually know which floor a lock is
            // associated with so we make the assumption there is only one
            // entrance given per community and one lock per community.
            // TODO This IS going to be an issue.
            lock={communityLocks?.[0]}
          />
        )}
        {markers.map((marker, i) => {
          switch (marker.type) {
            case DESTINATION_TYPES.UNIT:
              return (
                <UnitStep
                  url={getUnitURL(marker.item.id)}
                  key={i}
                  marker={marker}
                  hideStepLabels={hideStepLabels}
                  showUnitPrices={showUnitPrices}
                  showThumbnails={showThumbnails}
                  isShowPriceOnModel={isShowPriceOnModel}
                />
              )
            case DESTINATION_TYPES.AMENITY:
              return (
                <AmenityStep
                  url={getAmenityURL(marker.item.id)}
                  key={i}
                  marker={marker}
                  hideStepLabels={hideStepLabels}
                  showThumbnails={showThumbnails}
                />
              )
            default:
              return null
          }
        })}
      </div>
    </div>
  )
}

export function FloorList({
  floors,
  communityLocks,
  getUnitURL,
  getAmenityURL,
  showUnitPrices,
  hideStepLabels,
  stickyStepHeaderClassName,
  showThumbnails,
  isShowPriceOnModel,
}) {
  return (
    <>
      {floors.map((floor, i) => {
        // Break markers into leasing office markers
        // non-leasing office markers.
        const groups = floor.markers.reduce(
          (acc, curr) => {
            if (curr.type === DESTINATION_TYPES.LEASING_OFFICE)
              acc.offices.push(curr)
            else acc.markers.push(curr)
            return acc
          },
          { markers: [], offices: [] }
        )

        if (groups.markers.length > 0 || groups.offices.length > 0) {
          return (
            <TourFloor
              {...floor}
              key={i}
              markers={groups.markers}
              communityLocks={communityLocks}
              leasingOffices={groups.offices}
              getUnitURL={getUnitURL}
              getAmenityURL={getAmenityURL}
              showUnitPrices={showUnitPrices}
              hideStepLabels={hideStepLabels}
              stickyStepHeaderClassName={stickyStepHeaderClassName}
              showThumbnails={showThumbnails}
              isShowPriceOnModel={isShowPriceOnModel}
            />
          )
        } else {
          return null
        }
      })}
    </>
  )
}

export function TourBuilding({
  building,
  communityLocks,
  getUnitURL,
  getAmenityURL,
  showUnitPrices,
  hideStepLabels,
  stickyStepHeaderClassName,
  showThumbnails,
  isShowPriceOnModel,
}) {
  return (
    <div className={styles.TourBuilding} data-testid="TourBuilding">
      <div
        className={combineClasses(
          styles.buildingHeader,
          stickyStepHeaderClassName
        )}
      >
        <MiniTitle className={styles.buildingTitleLabel}>Building</MiniTitle>
        <SubTitle data-testid="buildingTitle" className={styles.buildingTitle}>
          {building.name}
        </SubTitle>
      </div>
      <div className={styles.floorList}>
        <FloorList
          floors={building.floors}
          communityLocks={communityLocks}
          getUnitURL={getUnitURL}
          getAmenityURL={getAmenityURL}
          showUnitPrices={showUnitPrices}
          hideStepLabels={hideStepLabels}
          stickyStepHeaderClassName={stickyStepHeaderClassName}
          showThumbnails={showThumbnails}
          isShowPriceOnModel={isShowPriceOnModel}
        />
      </div>
    </div>
  )
}

export function BuildingList({
  buildings,
  communityLocks,
  getUnitURL,
  getAmenityURL,
  showUnitPrices,
  hideStepLabels,
  stickyStepHeaderClassName,
  showThumbnails,
  isShowPriceOnModel,
}) {
  return (
    <>
      {buildings.map((building, i) => (
        <TourBuilding
          key={i}
          building={building}
          communityLocks={communityLocks}
          getUnitURL={getUnitURL}
          getAmenityURL={getAmenityURL}
          showUnitPrices={showUnitPrices}
          hideStepLabels={hideStepLabels}
          showThumbnails={showThumbnails}
          stickyStepHeaderClassName={stickyStepHeaderClassName}
          isShowPriceOnModel={isShowPriceOnModel}
        />
      ))}
    </>
  )
}

/**
 * @param {object} props
 * @param {object} props.stops
 * @param {boolean} [props.showUnitPrices]
 * @param {function} [props.getAmenityURL]
 * @param {function} [props.getUnitURL]
 * @param {boolean} [props.hideStepLabels]
 * @param {string} [props.className]
 * @param {string} [props.stickyStepHeaderClassName]
 * @param {boolean} [props.includeUngrouped] - Whether to include markers
 *   that don't have a floor plate.
 * @param {boolean} [props.showThumbnails]
 * @param {boolean} [props.isShowPriceOnModel]
 *   doors
 * @param {Nilable<object[]>} props.communityLocks
 */
export function TourSteps({
  stops,
  communityLocks,
  showUnitPrices,
  getAmenityURL,
  getUnitURL,
  hideStepLabels,
  className,
  stickyStepHeaderClassName,
  includeUngrouped,
  showThumbnails,
  isShowPriceOnModel,
  ...rest
}) {
  return (
    <div
      data-testid="TourSteps"
      className={combineClasses(styles.TourSteps, className)}
      {...rest}
    >
      {stops?.buildings && stops.buildings.length > 1 && (
        <BuildingList
          buildings={stops.buildings}
          communityLocks={communityLocks}
          getUnitURL={getUnitURL}
          getAmenityURL={getAmenityURL}
          showUnitPrices={showUnitPrices}
          hideStepLabels={hideStepLabels}
          stickyStepHeaderClassName={stickyStepHeaderClassName}
          showThumbnails={showThumbnails}
          isShowPriceOnModel={isShowPriceOnModel}
        />
      )}
      {stops?.buildings && stops.buildings.length === 1 && (
        <FloorList
          className={styles.noBuildings}
          floors={stops.buildings[0].floors}
          communityLocks={communityLocks}
          getUnitURL={getUnitURL}
          getAmenityURL={getAmenityURL}
          showUnitPrices={showUnitPrices}
          hideStepLabels={hideStepLabels}
          showThumbnails={showThumbnails}
          isShowPriceOnModel={isShowPriceOnModel}
        />
      )}
      {includeUngrouped && stops?.ungrouped?.markers?.length > 0 && (
        <TourFloor
          className={styles.noBuildings}
          name={
            stops.markers.length > stops.ungrouped.markers.length
              ? 'More Amenities'
              : null
          }
          markers={stops.ungrouped.markers}
          communityLocks={communityLocks}
          getUnitURL={getUnitURL}
          getAmenityURL={getAmenityURL}
          showUnitPrices={showUnitPrices}
          hideStepLabels={hideStepLabels}
          showThumbnails={showThumbnails}
          isShowPriceOnModel={isShowPriceOnModel}
        />
      )}
    </div>
  )
}

TourSteps.propTypes = {
  stops: TourPropType,
  showUnitPrices: PropTypes.bool,
  hideStepLabels: PropTypes.bool,
  getAmenityURL: PropTypes.func,
  getUnitURL: PropTypes.func,
  validateGetUrl: (props, propName, componentName) => {
    if (!props.getAmenityURL && !props.getUnitURL) {
      return new Error(
        'TourSteps requires either a "getAmenityURL" or "getUnitURL" prop. Recieved Neither'
      )
    }
  },
}
