import React from 'react'
import { color } from 'console-log-colors'

import { logFactory } from '~/util'
import { NotFound } from '~/components/not-found'

import './NotFound.scss'

const logger = logFactory('NotFound', color.bold.red)

/**
 * Renders the "page not found" page
 *
 * @typedef {object} Props
 *
 * @extends {React.Component<Props>}
 */
export default function NotFoundPage({
  title = 'Sorry,',
  subtitle = 'We Lost That',
  ...rest
}) {
  logger.log(rest)
  return <NotFound title={title} subtitle={subtitle} />
}
