import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import { combineClasses } from '@thesoulfresh/utils'

import { Address, DateLike, env, useAnalyticsClient } from '~/service'
import {
  selectCommunity,
  selectCommunityConfig,
  selectGuestCard,
  selectGuestCardUuid,
  useSaveUserIdVerification,
} from '~/store'
import { findHeaderImage, isDateToday, logFactory, removeNulls } from '~/util'
import { getRoute } from '../routes'
import {
  AccordionItem,
  Action,
  AppointmentWelcomeMessage,
  ImageHeader,
  Modal,
  NotFound,
  OrderedList,
  ResponsiveAccordion,
  Steps,
  toast,
} from '~/components'

import Check from '~/assets/icons/check-circle.svg?react'

import styles from './VerifyIdentity.module.scss'
import { color } from 'console-log-colors'
import { Nilable } from 'tsdef'

const logger = logFactory('VerifyIdentity', color.blue)

export interface PersonaModalProps
  extends React.HTMLAttributes<HTMLDivElement> {
  show: boolean
  onCancel: () => void
  onPersonaReady: () => void
  onComplete: (result: PersonaResult) => void
  onError: (error: unknown) => void
  Persona: any
  personaLoading: boolean
  personaTemplateId: string
  personaEnvironmentId: string
}

/**
 * Asynchronously loads the Persona SDK and displays the Persona UI in a Modal.
 */
export function PersonaModal({
  show,
  onCancel,
  onPersonaReady,
  onComplete,
  onError,
  Persona,
  personaLoading,
  personaTemplateId = env.personaTemplateId,
  personaEnvironmentId = env.personaEnvironmentId,
  ...rest
}: PersonaModalProps) {
  // Indicates that the Persona UI has finished rendering
  const [personaReady, setPersonaReady] = React.useState(false)

  const handlePersonaReady = () => {
    setPersonaReady(true)
    onPersonaReady && onPersonaReady()
  }

  logger.log(`personaEnvironmentId "${personaEnvironmentId}"`)

  return (
    <div data-testid="PersonaInline" className={styles.PersonaInline} {...rest}>
      {!personaLoading && !!Persona && (
        <Modal
          data-testid="PersonaModal"
          label="Verify Identity"
          // Keep the modal open because the Persona UI takes some time to load
          // and this ensures the it renders as quickly as possible when the
          // user clicks the Verify button. Keeping the modal open also ensures
          // that the user can resume the flow if they cancel it.
          isOpen={true}
          setIsOpen={onCancel}
          light
          fullscreen
          className={styles.modalContentWrapper}
          overlayClassName={combineClasses(
            styles.modalOverlay,
            // Hide the content using CSS insead of mounting/unmounting the
            // Persona UI because it's slow.
            personaReady && show ? styles.modalVisible : ''
          )}
          // Override the body styles set by react-modal
          bodyOpenClassName={styles.allowBodyScroll}
          htmlOpenClassName={null}
          ariaHideApp={false}
          shouldFocusAfterRender={false}
          lockBodyScroll={personaReady && show}
        >
          <div className={styles.modalContent}>
            {personaEnvironmentId && (
              // Persona will not be loaded unless the environment id is
              // defined. In `env.test`, the environment id doesn't exist and
              // can only be passed as a prop. This ensures we never make
              // requests to Persona during testing.
              <Persona.Inquiry
                // referenceId={userId}
                templateId={personaTemplateId}
                environmentId={personaEnvironmentId}
                onReady={() => {
                  handlePersonaReady()
                }}
                onComplete={onComplete}
                onError={onError}
              />
            )}
          </div>
        </Modal>
      )}
    </div>
  )
}

interface FollowUpStepsProps {
  communityName?: string
}

const FollowUpSteps = ({ communityName }: FollowUpStepsProps) => (
  <>
    <P>On the day of your tour, we’ll message you the details below:</P>
    <OrderedList>
      <li>Directions {communityName ? `to ${communityName}` : ''}</li>
      <li>Parking Instructions</li>
      <li>
        Community Access Instructions (door access won't be available until
        exact time of tour)
      </li>
      <li>
        A map of the facility with the apartments and amenities you will be
        touring.
      </li>
      <li>Information on pricing and community features.</li>
    </OrderedList>
  </>
)

interface VerifiedMessageProps {
  communityName?: string
}

function VerifiedMessage({ communityName }: VerifiedMessageProps) {
  return (
    <div data-testid="VerifiedMessage" className={styles.verifiedMessage}>
      <div className={styles.verifiedMessageText}>
        <FollowUpSteps communityName={communityName} />
      </div>
    </div>
  )
}

function P({
  className,
  ...props
}: React.HTMLAttributes<HTMLParagraphElement>) {
  return (
    <p className={combineClasses(styles.paragraph, className)} {...props} />
  )
}

interface StartVerificationProps {
  requiresUserAgreement: boolean
  communityName?: string
}

function PreVerificationMessage({
  requiresUserAgreement,
  communityName,
}: StartVerificationProps) {
  return (
    <div className={styles.PreVerificationMessage}>
      <ResponsiveAccordion className={styles.Accordion} dimension="l">
        <AccordionItem header="Why do I need to verify my ID?">
          For security purposes, we are required to know who will have access to
          the property. Think of it as the digital equivalent of leaving your
          drivers license at the Leasing Office while you tour the community.
        </AccordionItem>
        <AccordionItem header="Verification Steps">
          <Steps>
            <li>
              When you click “Verify Now”, you will be asked to take a photo of
              your government issued id.
            </li>
            <li>
              You will then be asked to take a photo of your face in order to
              verify it matches the photo on your id.
            </li>
            {requiresUserAgreement && (
              <li>
                Lastly, you need to agree to the {communityName} Self Tour
                terms.
              </li>
            )}
          </Steps>
        </AccordionItem>
        <AccordionItem header="That's It!">
          <FollowUpSteps communityName={communityName} />
        </AccordionItem>
      </ResponsiveAccordion>
    </div>
  )
}

type PersonaActionProps = Pick<
  VerifyIdentityProps,
  'saving' | 'verified' | 'saveError' | 'onRetrySave'
> & {
  setShowPersona: (show: boolean) => void
  showPersona: boolean
  personaReady: boolean
}

export const PersonaAction = React.memo(
  ({
    setShowPersona,
    showPersona,
    personaReady,
    saving,
    verified,
    saveError,
    onRetrySave,
    ...rest
  }: PersonaActionProps) => {
    return (
      <div
        className={combineClasses(
          styles.PersonaAction,
          saveError && styles.saveError
        )}
        {...rest}
      >
        {saveError && (
          <>
            <p className={styles.instructions}>
              Please check your Internet connection and try again.
            </p>
            <Action
              data-testid="retryButton"
              button
              display="error"
              onClick={onRetrySave}
              loading={saving}
              className={styles.verifyButton}
              category="user-id-verification"
              action="verification-start"
              label="restart"
            >
              Retry
            </Action>
          </>
        )}
        {!saveError && !verified && (
          <Action
            data-testid="verifyButton"
            button
            display="error"
            onClick={() => setShowPersona(true)}
            loading={(showPersona && !personaReady) || saving}
            className={styles.verifyButton}
            category="user-id-verification"
            action="verification-start"
            label="start"
          >
            Verify Your ID Prior to Tour
          </Action>
        )}
      </div>
    )
  }
)

interface PersonaResult {
  status: string
  inquiryId: string
}

export interface VerifyIdentityProps {
  communityName?: string
  communityAddress?: Address
  userName?: string
  tourStart?: DateLike
  headerImage?: string
  requiresUserAgreement: boolean
  onComplete: (result: PersonaResult) => void
  onError: (error: unknown) => void
  personaLoading: boolean
  personaError: Nilable<string>
  /**
   * Error indicating that the Verification process completed but we were unable
   * to store the results to our DB.
   */
  saveError: boolean
  /**
   * A callback to retry saving of the verification results if the previous save
   * request failed.
   */
  onRetrySave: () => void
  /**
   * We are currently attempting to save the verification results returned by
   * Persona.
   */
  saving: boolean
  Persona: any
  verified: boolean
  personaTemplateId?: string
  personaEnvironmentId?: string
}

/**
 * Show a button that opens the Persona UI.
 */
export const VerifyIdentity = React.forwardRef(
  (
    {
      communityName,
      communityAddress,
      userName,
      tourStart,
      headerImage,
      requiresUserAgreement,
      onComplete,
      onError,
      personaLoading,
      personaError,
      saveError,
      onRetrySave,
      saving,
      Persona,
      verified,
      personaTemplateId = env.personaTemplateId,
      personaEnvironmentId = env.personaEnvironmentId,
    }: VerifyIdentityProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const [showPersona, setShowPersona] = React.useState(false)
    const [personaReady, setPersonaReady] = React.useState(showPersona)

    const handleComplete = React.useCallback(
      (data: PersonaResult) => {
        setShowPersona(false)
        onComplete && onComplete(data)
      },
      [onComplete]
    )

    return (
      <div data-testid="VerifyIdentity" className={styles.VerifyIdentity}>
        <ImageHeader
          className={styles.ImageHeader}
          imageURL={headerImage}
          imageAlt={communityName}
          unstyled
          ref={ref}
        >
          <AppointmentWelcomeMessage
            title={
              saveError
                ? 'Oh no!'
                : verified
                ? `Thanks ${userName}!`
                : `Hi ${userName},`
            }
            subtitle={
              verified ? (
                <div className={styles.successSubtitle}>
                  Your appointment is confirmed.
                  <Check className={styles.checkmark} />
                </div>
              ) : saveError ? (
                'There was a problem saving your ID...'
              ) : (
                'One more step to confirm your appointment...'
              )
            }
            primaryProspect={userName}
            communityName={communityName}
            address={communityAddress}
            startDatetime={tourStart}
            className={styles.WelcomeMessage}
          >
            {!verified && !personaError && (
              <PersonaAction
                personaReady={personaReady}
                showPersona={showPersona}
                setShowPersona={setShowPersona}
                saving={saving}
                verified={verified}
                saveError={saveError}
                onRetrySave={onRetrySave}
              />
            )}
          </AppointmentWelcomeMessage>
        </ImageHeader>
        {!personaError && (
          <>
            {!verified && (
              <>
                <PersonaModal
                  show={showPersona}
                  onCancel={() => setShowPersona(false)}
                  onPersonaReady={() => setPersonaReady(true)}
                  onComplete={handleComplete}
                  onError={onError}
                  Persona={Persona}
                  personaLoading={personaLoading}
                  personaTemplateId={personaTemplateId}
                  personaEnvironmentId={personaEnvironmentId}
                />
                <PreVerificationMessage
                  requiresUserAgreement={requiresUserAgreement}
                  communityName={communityName}
                />
              </>
            )}
            {verified && <VerifiedMessage communityName={communityName} />}
          </>
        )}
        {personaError && (
          <NotFound
            data-testid="PersonaError"
            title="Unable to create your tour"
            subtitle={`Please contact ${communityName} to let them know you were unable to access your tour.`}
          />
        )}
      </div>
    )
  }
)

export type PersonaSDK = typeof import('persona')

/**
 * Lazy load the Persona SDK
 */
function usePersonaSDK(onError: (error: unknown) => void) {
  const [Persona, setPersona] = React.useState<PersonaSDK>()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState<string>()

  React.useEffect(
    () => {
      // Bundle Persona in it's own bundle so users don't have to download it
      // unless they visit this page.
      import('persona')
        .then((persona) => {
          setLoading(false)
          setError(undefined)
          setPersona(persona)
        })
        .catch((error: string) => {
          console.error('[VerifyIdentity] Failed to load Persona SDK', error)
          setLoading(false)
          setError(error)
          setPersona(undefined)
          onError && onError(error)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  return [loading, error, Persona] as const
}

function getClosestDate(dates: DateLike[] = []) {
  if (!dates?.length) return undefined

  return dates
    .filter(removeNulls)
    .map((d) => new Date(d))
    .sort((a, b) => a.getTime() - b.getTime())
    .find((d) => {
      const today = new Date()
      return (
        d.getFullYear() >= today.getFullYear() &&
        d.getMonth() >= today.getMonth() &&
        d.getDate() >= today.getDate()
      )
    })
  // .find((d) => d >= new Date())
}

/**
 * `<VerifyIdentityConnected>` connects the VerifyIdentity
 * component with the rest of the app (ie. routing, services, store, etc.).
 * The Verify Identity page is used to verify a user's identity using their
 * driver's license or other goverment issued id.
 */
export function VerifyIdentityConnected() {
  const uuid = useSelector(selectGuestCardUuid)
  const community = useSelector(selectCommunity)
  const guestCard = useSelector(selectGuestCard)
  const config = useSelector(selectCommunityConfig)

  const headerImage = findHeaderImage(community)
  const primaryProspect = guestCard?.primaryProspect
  const closestAppointment = getClosestDate(
    guestCard?.appointments?.map((a) => a.scheduled)
  )
  const tourIsToday = !!closestAppointment && isDateToday(closestAppointment)

  const [verified, setVerified] = React.useState(false)
  const [saveError, setSaveError] = React.useState<string>()
  const [saving, setSaving] = React.useState(false)
  const saveUserVerification = useSaveUserIdVerification()
  const analytics = useAnalyticsClient()

  const headerRef = React.useRef<HTMLDivElement>(null)
  const history = useHistory()

  /**
   * Called when the verification process completes successfully or when we need
   * to retry saving the verification results because our API previously failed.
   */
  const onSave = async (inquiryId: string) => {
    try {
      logger.log('saving verification', inquiryId)
      setSaving(true)
      await saveUserVerification(inquiryId)
      setSaving(false)
      setVerified(true)

      analytics.trackEvent(
        'user-id-verification',
        'verification-complete',
        'success'
      )

      logger.log('is tour today?', tourIsToday)
      if (tourIsToday) {
        history.push(getRoute('SELF_TOUR_DIRECTIONS', { uuid }))
      }
    } catch (e) {
      toast.error(
        'We were unable to save your verification. Please check your Internet connection and try again..'
      )
      console.error('Unable to save user verification:', e)
      setSaving(false)
      setSaveError(inquiryId)
      analytics.trackEvent(
        'user-id-verification',
        'verification-error',
        'powerpro-api-error'
      )
    }
  }

  /**
   * Called when the Persona verification process completes.
   */
  const onComplete = async (data: PersonaResult) => {
    if (data.status === 'completed') {
      logger.log('Completed verification:', data)

      // Bring the important content into view.
      // Note: in the test environment, Element.scrollIntoView may not be implemented.
      headerRef.current?.scrollIntoView?.({ behavior: 'instant' })

      onSave(data.inquiryId)
    } else {
      console.error('Unable to complete verification:', data)
      analytics.trackEvent(
        'user-id-verification',
        'verification-error',
        'persona-error'
      )
    }
  }

  const onError = (error: unknown) => {
    // The user will see errors in the Persona interface so we don't have much
    // need to do anything here. We don't need to save the "error" state to the
    // backend because leaving the verified state will force the user to
    // re-verify on their next visit (or if they press the Retry button in this
    // session).
    //
    // Possible Persona errors:
    // https://docs.withpersona.com/docs/embedded-flow-event-handling#onerror
    console.error('Error completing inquiry', error)
    analytics.trackEvent(
      'user-id-verification',
      'verification-error',
      'persona-error'
    )
  }

  // Do lazy loading here so we can easily mock the PersonaSDK during test.
  const [loading, error, Persona] = usePersonaSDK(onError)

  // Redirect if the user is already verified
  if (primaryProspect?.idInquiry?.status === 'COMPLETED') {
    console.info('User is already verified:', primaryProspect.idInquiry)
    return <Redirect to={getRoute('SELF_TOUR_DIRECTIONS', { uuid })} />
  } else {
    return (
      <VerifyIdentity
        onComplete={onComplete}
        onError={onError}
        Persona={Persona}
        personaLoading={loading}
        personaError={error}
        saveError={!!saveError}
        onRetrySave={() => onSave(saveError!)}
        saving={saving}
        communityName={community?.name}
        communityAddress={config?.address}
        userName={primaryProspect?.firstName}
        tourStart={closestAppointment}
        headerImage={headerImage}
        verified={verified}
        requiresUserAgreement={false}
        ref={headerRef}
      />
    )
  }
}
