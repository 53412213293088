import React from 'react'

import { combineClasses } from '~/util'

import { Title } from '../titles'
import { Image } from '../image'

import styles from './ImageHeader.module.scss'

interface ImageHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  headerTitleClassName?: string
  imageURL?: string
  imageAlt?: string
  title?: string
  /**
   * Hides the background image.
   */
  transparent?: boolean
  /**
   * Removes any styling applied to the wrapper around the centered content.
   * This gives you full controll over the content.
   */
  unstyled?: boolean
}

/**
 * This component renders a page header that can hold
 * a hero image, a title and/or a subtitle.
 */
export const ImageHeader = React.forwardRef(
  (
    {
      className,
      headerTitleClassName,
      imageURL,
      imageAlt,
      title,
      children,
      /**
       * Hides the background image.
       */
      transparent,
      /**
       * Removes any styling applied to the wrapper around the centered content.
       * This gives you full controll over the content.
       */
      unstyled,
      ...rest
    }: ImageHeaderProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const hasBackground = imageURL || !transparent

    return (
      <div
        data-testid="ImageHeader"
        className={combineClasses(
          styles.ImageHeader,
          className,
          transparent ? styles.transparent : null,
          !imageURL ? styles.noImage : styles.withImage,
          children ? styles.withSubText : null,
          unstyled ? styles.unstyled : null
        )}
        {...rest}
      >
        {hasBackground && (
          <div className={styles.background}>
            {imageURL && (
              <Image
                data-testid="headerImage"
                className={styles.image}
                src={imageURL}
                alt={imageAlt}
                loader={false}
                width="100%"
                height="100%"
                responsive
              />
            )}
          </div>
        )}
        {(title || children) && (
          <div
            className={combineClasses(
              hasBackground ? styles.withBackground : styles.noBackground,
              styles.textContainer
            )}
            ref={ref}
          >
            {title && (
              <Title
                data-testid="headerTitle"
                className={combineClasses(styles.title, headerTitleClassName)}
              >
                {title}
              </Title>
            )}
            {children && (
              <div data-testid="subText" className={styles.subText}>
                {children}
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
)
