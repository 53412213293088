type Color = (str: string) => string

// Don't import the `env` service because that will cause cyclic dependencies.
// TODO Could fix that by moving the env logging out of the env service.
const test = import.meta.env.VITE_ENV === 'test'

// In test we want to specifically enable/disable logging.
let enabled = !test

// TODO Turn off logging in production at startup
// TODO Allow turning verbose logging on/off via a query parameter
// TODO Ansi colors aren't working in chrome. We could submit a PR to add rgb
// support: console.log("\x1B[38;2;250;0;0m[env]\x1B[0m")

/**
 * Globally enable logging.
 */
export const enableLogging = () => (enabled = true)
/**
 * Globally disable logging.
 */
export const disableLogging = () => (enabled = false)

type ColoredLogFunctions = 'log' | 'error' | 'warn' | 'info' | 'debug'

/**
 * Create a log function that can be globally enabled/disabled and can log
 * colored output to the console. It also works in both the browser console and
 * the terminal.
 *
 * To enable/disable logging, use the `enableLogging` and `disableLogging`
 * functions at points when you want to enable/disable logging.
 *
 * To log colored output, pass an array of text/style pairs to the log function
 * where the text is a string and the style is a function from `console-log-colors`.
 * The style pairs can be of the types `[string, Color][]`, `[string, Color]` or `string, Color`.
 * Anything you pass after the style pairs will be logged without coloring.
 *
 * @example
 * ```ts
 * const l = logger('DoorCode', color.green)
 *
 * l.log([['---> helloooo?', color.red]]) // red text
 * l.log([
 *   ['---> helloooo?', color.red],
 *   ['w00t', color.bold.blue],
 * ]) // red text and blue text
 *
 * // some shortcuts for the above if you only want to color the first item
 * l.log(['??? shortcut', color.red])
 * l.log('this', { foo: 'bar' })
 *
 * disableLogging()
 * l.log('this should not log')
 *
 * enableLogging()
 * l.log('<---- the shortest', color.red, 'more stuff')
 * ```
 */
export const logFactory = (prefix = '', color: Color = (s: string) => s) => {
  const prefixColored = prefix ? color(`[${prefix}]`) : ''

  const wrap = (prop: ColoredLogFunctions) => {
    return (
      coloredLogs: [string, Color][] | [string, Color] | string,
      maybeColor?: Color | any,
      ...rest: any[]
    ) => {
      let formatted = false
      let front = coloredLogs
      if (Array.isArray(coloredLogs) && coloredLogs.length > 0) {
        if (
          Array.isArray(coloredLogs[0]) &&
          typeof coloredLogs[0][0] === 'string' &&
          typeof coloredLogs[0][1] === 'function'
        ) {
          front = (coloredLogs as [string, Color][])
            .map(([text, style]) => {
              const out = style(text)
              // console.log('stylized', out)
              return out
            })
            .join(' ')
          formatted = true
        } else if (
          typeof coloredLogs[0] === 'string' &&
          typeof coloredLogs[1] === 'function'
        ) {
          front = coloredLogs[1](coloredLogs[0])
          formatted = true
        }
      } else if (
        typeof coloredLogs === 'string' &&
        typeof maybeColor === 'function'
      ) {
        front = maybeColor(coloredLogs)
        formatted = true
      }

      if (enabled) {
        if (formatted) {
          // This was necessary because if we pass ([[string, color]], string),
          // the last param should be logged but if we pass (string, color),
          // the last param should not. This solution is a little ugly and I
          // think it really comes down to the API being too clever. Trying to
          // provide the convenience of `(string, color)` and `([string,
          // color])` makes it hard to know when we are or are not using a
          // shortcut. It might be better to require the
          // `([[string, color]], ...rest)` API only. For the moment, I'm
          // keeping the convenient API because it really is convenient.
          if (typeof maybeColor !== 'function') {
            rest.unshift(maybeColor)
          }
          console[prop].call(
            console,
            [prefixColored, front]
              .join(' ')
              // To make tests easier
              .trim(),
            // If there are no ...rest params, this will pass undefined but we
            // can't remove undefineds in case we actually intended to log an
            // undefined value
            ...rest
          )
        } else {
          console[prop].call(
            console,
            [prefixColored, front]
              .join(' ')
              // To make tests easier
              .trim(),
            maybeColor,
            // If there are no ...rest params, this will pass undefined but we
            // can't remove undefineds in case we actually intended to log an
            // undefined value
            ...rest
          )
        }
      }
    }
  }

  return {
    log: wrap('log'),
    error: wrap('error'),
    warn: wrap('warn'),
    info: wrap('info'),
    debug: wrap('debug'),
  }
}
