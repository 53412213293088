import { Address } from '~/service'
import { removeNulls } from '.'

/**
 * Append a path string to a URL, ensuring that we don't end up with multiple
 * path separators.
 */
export function concatURL(URL: string, ...paths: string[]) {
  return [
    URL.replace(/\/$/, ''),
    ...paths
      .filter((p) => !!p)
      .map((p) => p.replace(/^\//, '').replace(/\/$/, '')),
  ].join('/')
}

/**
 * Generate a Google Maps URL for a given address.
 */
export function googleMapsLink(address: Address) {
  const query = [
    address.addressLines?.join(' '),
    address.city,
    address.state,
    address.postalCode,
  ]
    .filter(removeNulls)
    .join(',')

  return `https://www.google.com/maps/search/?api=1&q=${query}`
}
