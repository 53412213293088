import React from 'react'

import { combineClasses } from '~/util'

import { Action, ActionProps, ActionRef } from './Action'

import styles from './ReusableActions.module.scss'

import PhoneIcon from '~/assets/icons/phone.svg?react'
import MessageCircleIcon from '~/assets/icons/message-circle.svg?react'
import MessageSquareIcon from '~/assets/icons/message-square.svg?react'
import MailIcon from '~/assets/icons/mail.svg?react'
import ExternalLinkIcon from '~/assets/icons/external-link.svg?react'
import CloseIcon from '~/assets/icons/x.svg?react'
import PlayIcon from '~/assets/icons/play.svg?react'
import ZoomInIcon from '~/assets/icons/zoom-in.svg?react'
import ZoomOutIcon from '~/assets/icons/zoom-out.svg?react'
import ChevronRight from '~/assets/icons/chevron-right.svg?react'
import ChevronLeft from '~/assets/icons/chevron-left.svg?react'
import ThreeIcon from '~/assets/icons/3D.svg?react'
import HeartIcon from '~/assets/icons/heart.svg?react'
import MapPinIcon from '~/assets/icons/map-pin.svg?react'

const forwardRef = React.forwardRef<ActionRef, ActionProps>

export const VirtualTourAction = forwardRef((props, ref) => (
  <Action icon={<ThreeIcon />} {...props} ref={ref} />
))

export const CallAction = forwardRef((props, ref) => (
  <Action icon={<PhoneIcon />} {...props} ref={ref} />
))

// Use this for text messaging links/buttons.
export const SMSAction = forwardRef((props, ref) => (
  <Action icon={<MessageCircleIcon />} {...props} ref={ref} />
))

/**
 * Use this for chat bots and pure chat buttons.
 */
export const ChatAction = forwardRef((props, ref) => (
  <Action icon={<MessageSquareIcon />} {...props} ref={ref} />
))

export const EmailAction = forwardRef((props, ref) => (
  <Action icon={<MailIcon />} {...props} ref={ref} />
))

export const ExternalLinkAction = forwardRef((props, ref) => (
  <Action icon={<ExternalLinkIcon />} {...props} ref={ref} />
))

export const CloseAction = forwardRef(({ className, ...props }, ref) => (
  <Action
    className={combineClasses(styles.close, className)}
    icon={<CloseIcon />}
    {...props}
    ref={ref}
  />
))

export const ZoomInAction = forwardRef((props, ref) => (
  <Action
    icon={<ZoomInIcon data-testid="zoomOutIcon" />}
    {...props}
    ref={ref}
  />
))

export const ZoomOutAction = forwardRef((props, ref) => (
  <Action
    icon={<ZoomOutIcon data-testid="zoomInIcon" />}
    {...props}
    ref={ref}
  />
))

export const PreviousAction = forwardRef((props, ref) => (
  <Action icon={<ChevronLeft />} {...props} ref={ref} />
))

export const NextAction = forwardRef((props, ref) => (
  <Action icon={<ChevronRight />} {...props} ref={ref} />
))

export const PlayVideoAction = forwardRef(({ className, ...props }, ref) => (
  <Action
    className={combineClasses(styles.play, className)}
    icon={<PlayIcon />}
    {...props}
    ref={ref}
  />
))

export const FavoritesAction = forwardRef(({ className, ...props }, ref) => {
  return (
    <Action
      className={combineClasses(styles.favorites, className)}
      icon={<HeartIcon />}
      {...props}
      ref={ref}
    />
  )
})

export const MapPinAction = forwardRef(({ className, ...props }, ref) => (
  <Action
    className={combineClasses(styles.mapPin, className)}
    icon={<MapPinIcon />}
    {...props}
    ref={ref}
  />
))
