import React from 'react'
import { color } from 'console-log-colors'

import { BundleLoadError } from './components/bundle-load-error'
// import { PageLoader, BundleLoadError } from './components'
import { env } from './service/env'
import { logFactory } from './util/logger'
import { WithServer, WithServerProps } from './pages/main/WithServer'

import './App.scss'

const logger = logFactory('App', color.bold.gray)

logger.log('--- STARTING APP ---', color.grey)
env.log()

export interface AppProps extends WithServerProps {
  /**
   * A component to use in place of `WithServer`
   */
  children?: React.ReactNode
}

/**
 * The `App.api` component is responsible for creating the app root element and
 * error handler. It will then render the `WithServer` component or the
 * `children` prop if one is passed. This allows the `App.mock` component to
 * substitude `WithMocks` for `WithServer`.
 */
export default function App({ children, ...props }: AppProps) {
  return (
    <div className="App">
      <BundleLoadError>
        {children ? children : <WithServer {...props} />}
      </BundleLoadError>
    </div>
  )
}
