import React from 'react'

import { combineClasses } from '~/util'

import { Label } from '../../labels'
import { ItemName } from '../../titles'
import { Card, CardProps } from '../Card'

import StarIcon from '~/assets/icons/star.svg?react'

import styles from './LeasingOfficeCard.module.scss'

export interface LeasingOfficeCardProps extends CardProps {
  /**
   * The name of this leasing office. Defaults to 'Leasing Office' if not
   * specified.
   */
  name?: string
}

/**
 * Displays summary information about a leasing office.
 */
export const LeasingOfficeCard = React.memo(
  ({
    name = 'Leasing Office',
    transparent,
    className,
    ...rest
  }: LeasingOfficeCardProps) => {
    return (
      <Card
        data-testid="LeasingOfficeCard"
        className={combineClasses(styles.LeasingOfficeCard, className)}
        transparent={transparent}
        {...rest}
      >
        <Label
          className={styles.label}
          size="s"
          children={<StarIcon />}
          display="error"
        />
        <ItemName className={styles.title} data-testid="leasingOfficeCardTitle">
          {name}
        </ItemName>
      </Card>
    )
  }
)
