import React from 'react'
import useLockBodyScroll from 'react-use/lib/useLockBodyScroll'

import {
  combineClasses,
  useDropdownAria,
  useFocusTrap,
  useKeyWhenActive,
} from '~/util'
import { ZoomInAction, ZoomOutAction } from '../../buttons'
import { PageTakeover } from '../../page-takeover/index'
import { MapSwitcher } from './MapSwitcher'
import {
  ANALYTICS_CATEGORIES,
  Amenity,
  Floor,
  FloorPlate,
  Marker,
  Unit,
} from '~/service'

import styles from './ExpandableMapSwitcher.module.scss'

interface ExpandableMapContentProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'children'> {
  maps: Floor[]
  expanded?: boolean
  preExpanded?: boolean
  onToggleExpand?: () => void
  elementRef?: React.Ref<HTMLDivElement>
  children: (props: MapContentProps) => React.ReactNode
}

function ExpandableMapContent({
  maps,
  expanded = false,
  preExpanded,
  onToggleExpand,
  elementRef,
  className,
  children,
  ...rest
}: ExpandableMapContentProps) {
  const aria = useDropdownAria(expanded)
  // When the map is fullscreen, it should act as a focus trap
  // so that elements behind the map are not reachable with
  // the keyboard.
  const [firstFocusableRef, lastFocusableRef] = useFocusTrap(expanded)

  useKeyWhenActive('Escape', onToggleExpand, expanded)

  return (
    <div
      className={combineClasses(styles.ExpandableMapContent, className)}
      ref={elementRef}
      {...rest}
    >
      <MapSwitcher
        maps={maps}
        className={styles.MapSwitcher}
        {...aria.menuProps}
      >
        {(props) =>
          children({
            ...props,
            expanded,
            preExpanded,
            onToggleExpand,
            firstFocusableRef,
          })
        }
      </MapSwitcher>
      {preExpanded && (
        <ZoomOutAction
          data-testid="zoomOutButton"
          className={styles.zoomButton}
          onClick={onToggleExpand}
          button
          size="m"
          transparent
          category={ANALYTICS_CATEGORIES.MAP}
          action="Zoom"
          label="Zoom Out"
          {...(aria.triggerProps as any)}
          ref={lastFocusableRef as any}
        />
      )}
      {!preExpanded && (
        <ZoomInAction
          data-testid="zoomInButton"
          className={styles.zoomButton}
          onClick={onToggleExpand}
          button
          size="m"
          transparent
          category={ANALYTICS_CATEGORIES.MAP}
          action="Zoom"
          label="Zoom In"
          {...(aria.triggerProps as any)}
          ref={lastFocusableRef as any}
        />
      )}
    </div>
  )
}

interface MapContentProps {
  /**
   * The index of the current map
   */
  index: number
  floorPlate: FloorPlate
  markers: Marker<Amenity | Unit>[]
  units: Unit[]
  amenities: Amenity[]
  leasingOffices: Amenity[]
  expanded: boolean
  firstFocusableRef: any
  /**
   * A callback you must use to show
   * the map content once it is loaded.
   */
  onShow: () => void
}

export interface ExpandableMapSwitcherProps extends ExpandableMapContentProps {
  /**
   * true = Allow the body to scroll when in the expanded state.
   * false = Prevent document.body from scrolling when in the expanded state.
   */
  allowBodyScroll?: boolean
}

/**
 * A `MapSwitcher` component that can also expand to take over
 * the full page.
 * @param {object} props
 * @param {object[]} props.maps
 * @param {boolean} [props.allowBodyScroll]
 * @param {string} [props.className]
 */
export const ExpandableMapSwitcher = React.forwardRef(
  (
    { maps, allowBodyScroll, className, ...rest }: ExpandableMapSwitcherProps,
    ref
  ) => {
    const [expanded, setExpanded] = React.useState(false)

    const onMinimize = () => setExpanded(false)
    const onMaximize = () => setExpanded(true)

    // Prevent page scrolling while expanded.
    useLockBodyScroll(expanded && !allowBodyScroll)

    return (
      <PageTakeover
        className={combineClasses(styles.ExpandableMapSwitcher, className)}
        onMaximize={onMaximize}
        onMinimize={onMinimize}
        ref={ref}
      >
        <ExpandableMapContent maps={maps} {...rest} />
      </PageTakeover>
    )
  }
)
