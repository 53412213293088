// TODO Move this out of service
import { DateLike } from '~/service'

export const SECOND = 1000
export const MINUTE = SECOND * 60
export const HOUR = MINUTE * 60

/**
 * Determins whether the date being compared is within 1hr of now.
 */
export const isTimeWithinTheHour = (appointmentTime: DateLike) => {
  const difference = new Date(appointmentTime).getTime() - new Date().getTime()
  return difference <= HOUR && difference >= HOUR * -1
}

export function minToMilli(min: number) {
  return min * MINUTE
}

export function milliToSec(milli: number) {
  return milli / SECOND
}

export function milliToMin(milli: number) {
  return milli / MINUTE
}

export function milliToHours(milli: number) {
  return milli / HOUR
}

export function formatHours(milli: number) {
  // TODO Use duration faling back to this
  const hours = Math.floor(milliToHours(milli))
  // I'm not sure which is better here (floor, ceil or round).
  // I'm going with round because it's the closest representation of the number
  // of minutes left.
  const minutes = Math.round(milliToMin(milli % HOUR))
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
}

export function formatMinutes(milli: number) {
  const minutes = Math.floor(milliToMin(milli % HOUR))
  const seconds = Math.ceil(milliToSec(milli % MINUTE) - 1)
  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
    2,
    '0'
  )}`
}

export function formatSeconds(milli: number) {
  return String(Math.floor(milliToSec(milli))).padStart(2, '0')
}

/**
 * Format a time as HH:MM:SS.sss
 */
export function formatTimer(date: DateLike = new Date()) {
  const d = new Date(date)
  return `${formatHours(
    d.getHours() * HOUR + d.getMinutes() * MINUTE
  )}:${formatSeconds(d.getSeconds() * SECOND)}.${d.getMilliseconds()}`
}

/**
 * Format a remaining time amount. This defaults to HH:MM until there is less
 * than 1 minute remaining, then it is formatted as SSs. However, if you pass
 * `minutesOnly` as true, it will always be formatted as MM:SS.
 */
export function formatTimeRemaining(
  remaining: number,
  minutesOnly: boolean | undefined
): string {
  if (minutesOnly) {
    return formatMinutes(remaining)
  }

  const lessThanMinute = remaining < minToMilli(1)
  return lessThanMinute
    ? // Show the time in seconds
      formatSeconds(remaining) + 's'
    : // Show the time in HH:MM
      formatHours(remaining)
}
