import { combineClasses } from '@thesoulfresh/utils'
import { Nilable } from 'tsdef'

import { Lock } from '~/service'
import { Label } from '../../labels'
import { ItemName } from '../../titles'
import { DoorCodeTimer } from '../../door-code'
import { Card, CardProps } from '../Card'

import StarIcon from '~/assets/icons/star.svg?react'
import styles from './EntranceCard.module.scss'

export interface EntranceCardProps extends CardProps {
  /**
   * The name of this leasing office. Defaults to 'Leasing Office' if not
   * specified.
   */
  name?: string
  /**
   * The lock device associated with this entrance.
   */
  lock: Nilable<Lock>
}

/**
 * `<Entrance>` shows the summary of an entrance such as the lock codes used to
 * open that entrance.
 */
export const EntranceCard = ({
  name = 'Main Entrance',
  lock,
  transparent,
  className,
  ...rest
}: EntranceCardProps) => {
  return (
    <Card
      data-testid="EntranceCard"
      className={combineClasses(styles.EntranceCard, className)}
      transparent={transparent}
      {...rest}
    >
      <Label
        className={styles.label}
        size="s"
        children={<StarIcon />}
        display="error"
      />
      <ItemName className={styles.title} data-testid="EntranceCard.title">
        {name}
      </ItemName>
      {lock && <DoorCodeTimer className={styles.DoorCode} lock={lock} />}
    </Card>
  )
}
