import React from 'react'
import { combineClasses } from '~/util'

import styles from './OrderedList.module.scss'

export interface OrderedListProps
  extends React.HTMLAttributes<HTMLOListElement> {}

/**
 * `<OrderedList>` renders an `<ol>` with our app styles.
 *
 * @param {object} props
 * @param {string} [props.className]
 */
export function OrderedList({
  className,
  children,
  ...rest
}: OrderedListProps) {
  return (
    <ol
      data-testid="OrderedList"
      className={combineClasses(styles.OrderedList, className)}
      {...rest}
    >
      {children}
    </ol>
  )
}
