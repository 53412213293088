import React from 'react'
import type { Nilable } from 'tsdef'

import {
  combineClasses,
  formatDoorCode,
  formatTime,
  formatTimeRange,
  minToMilli,
} from '~/util'
import { ItemName } from '../titles'

import styles from './DoorCode.module.scss'
import { Action } from '../buttons'
import LockIcon from '~/assets/icons/lock.svg?react'
import { Lock } from '~/service'
import { TimedAccess } from '../timed-access'

interface LockCodeProps extends React.HTMLAttributes<HTMLDivElement> {
  code: string
  /**
   * The manufaturer of the lock. This is used to determine formatting of the
   * lock code.
   */
  type: Nilable<string>
}

export function LockCode({ code, type, ...rest }: LockCodeProps) {
  return (
    <div data-testid="LockCode" className={styles.LockCode} {...rest}>
      <LockIcon className={styles.lockIcon} />
      <ItemName data-testid="code" className={styles.code} level={undefined}>
        {formatDoorCode(code, type)}
      </ItemName>
    </div>
  )
}

interface DoorCodeProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * The lock device description.
   */
  lock: Lock
  /**
   * The number of minutes before `lock.endTime` that
   * the component will show a warning that access is about to end.
   */
  warningTime?: number
  /**
   * Allows you to mock the time that the door code thinks it is (ie. Date.now()).
   */
  currentTime?: Date
  /**
   * Whether to show a simplified view of the door code.
   */
  simple?: boolean
}

/**
 * `<DoorCode>` displays the access code for an apartment and the length of time
 * that code can be used. If the code is not available yet or is in the future,
 * it is not displayed to ensure the user cannot access the unit.
 */
export const DoorCode = React.memo(
  ({
    lock,
    warningTime = 15,
    currentTime = new Date(),
    simple = true,
    className,
    ...rest
  }: DoorCodeProps) => {
    const start = React.useMemo(
      () => (lock.startTime ? new Date(lock.startTime) : currentTime),
      [currentTime, lock.startTime]
    )
    const end = React.useMemo(
      () => (lock.endTime ? new Date(lock.endTime) : undefined),
      [lock.endTime]
    )

    const started = currentTime >= start
    const ended = end ? currentTime >= end : false
    const canAccess = started && !ended

    const length = end ? end.getTime() - start.getTime() : undefined
    const remaining = end ? end.getTime() - currentTime.getTime() : undefined
    const minutesLeft = remaining ? Math.round(remaining / 60000) : undefined
    const almostUp =
      canAccess && minutesLeft != null && minutesLeft < warningTime
    const showButton = !lock.pin && !!lock.webUrl

    return (
      <div
        data-testid="DoorCode"
        className={combineClasses(
          styles.DoorCode,
          className,
          canAccess && styles.accessible,
          almostUp && styles.almostUp,
          ended && styles.expired,
          showButton && styles.urlLock
        )}
        {...rest}
      >
        {!simple && (
          <span data-testid="DoorCode.label" className={styles.label}>
            {showButton ? 'Access' : 'Door Code'}:
          </span>
        )}
        <span data-testid="DoorCode.code" className={styles.codeValue}>
          {React.useMemo(() => {
            if (canAccess) {
              if (showButton) {
                return (
                  <Action
                    className={styles.unlockButton}
                    feel="button"
                    display="primary"
                    href={lock.webUrl}
                    blank
                    icon={<LockIcon className={styles.lockIcon} />}
                  >
                    Unlock
                  </Action>
                )
              } else if (lock.pin) {
                return <LockCode code={lock.pin} type={lock.manufacturer} />
              }
            } else {
              if (ended) {
                // After access
                return (
                  <ItemName className={styles.message} level={false}>
                    Access
                  </ItemName>
                )
              }
            }
            // Before access or no door code
            return (
              <ItemName className={styles.message} level={undefined}>
                {simple ? `Access ${formatTime(start)}` : 'Soon'}
              </ItemName>
            )
          }, [
            canAccess,
            simple,
            start,
            showButton,
            lock.pin,
            lock.webUrl,
            lock.manufacturer,
            ended,
          ])}
        </span>
        {!simple && (
          <span
            data-testid="DoorCode.accessTime"
            className={combineClasses(styles.time, styles.label)}
          >
            {(lock.startTime || lock.endTime) &&
              formatTimeRange(lock.startTime, lock.endTime)}
          </span>
        )}
        {(!simple || (simple && (almostUp || ended))) && (
          <span className={styles.remaining}>
            {length != null && remaining != null && (
              <TimedAccess
                minutesOnly={simple}
                length={length}
                remaining={remaining}
                // remaining={length === remaining ? remaining - 1 : remaining}
                warningThreshold={minToMilli(warningTime)}
              />
            )}
          </span>
        )}
      </div>
    )
  }
)

/**
 * Renders `<DoorCode>` while keeping it in sync with the current time.
 */
export const DoorCodeTimer = React.memo((props: DoorCodeProps) => {
  const [currentTime, setCurrentTime] = React.useState(new Date())

  React.useEffect(() => {
    const timeout = setInterval(() => {
      setCurrentTime(new Date())
    }, 1000)
    return () => clearInterval(timeout)
  }, [])

  return <DoorCode currentTime={currentTime} {...props} />
})
