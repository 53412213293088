import React from 'react'
import ReactDOM from 'react-dom'
import { createHtmlPortalNode, InPortal, OutPortal } from 'react-reverse-portal'
import { mergeRefs } from 'react-merge-refs'

import { combineClasses } from '~/util'

import styles from './PageTakeover.module.scss'

interface BodyPortalProps {
  selector?: string
  children: React.ReactNode
}

/**
 * If we need to append the page takeover to the body element,
 * we can use this portal class in the future.
 */
const BodyPortal = React.forwardRef<HTMLDivElement, BodyPortalProps>(
  ({ selector = 'body', children }, ref) => {
    const [portal] = React.useState(() => {
      const p = document.createElement('div')
      p.classList.add('page-takeover-portal')
      p.setAttribute('data-testid', 'PageTakeoverPortal')
      p.style.position = 'absolute'
      p.style.top = '0'
      p.style.left = '0'
      if (typeof ref === 'function') ref(p)
      else if (ref && typeof ref === 'object') ref.current = p
      return p
    })

    React.useEffect(() => {
      let parent = document.querySelector(selector)
      if (!parent) {
        console.warn(
          `[PageTakeover] Unable to find element ${selector} to insert full page content. Falling back to "document.body".`
        )
        parent = document.body
      }
      parent.appendChild(portal)
      return () => {
        parent?.removeChild(portal)
      }
    })

    return ReactDOM.createPortal(children, portal)
  }
)

/**
 * Render the children in the element defined by selector
 * or just render the children directly if a selector is not passed.
 */
const PortalLocation = React.forwardRef<HTMLDivElement, BodyPortalProps>(
  ({ selector, children }, ref) => {
    if (selector) {
      return (
        <BodyPortal selector={selector} ref={ref}>
          {children}
        </BodyPortal>
      )
    } else {
      return children
    }
  }
)

interface PageTakeoverProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * A callback that will be notified when an minimize animation starts.
   */
  onMinimize?: (...args: unknown[]) => unknown
  /**
   * A callback that will notify whenever a minimize animation is finished.
   */
  onMinimizeComplete?: (...args: unknown[]) => unknown
  /**
   * A callback that will be notified when an maximize animation starts.
   */
  onMaximize?: (...args: unknown[]) => unknown
  /**
   * A callback that will notify whenever a maximize animation is finished.
   */
  onMaximizeComplete?: (...args: unknown[]) => unknown
  /**
   * A selector that will be used to place the content when it is in the "takeover"
   * state. If a selector is not specified, the content is placed as a sibling of
   * its original location but absolutely positioned.
   */
  takeoverSelector?: string
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>
}

/**
 * Renders its children either inline or in an absolutetly positioned element
 * that fills the closest relative ancestor.
 */
export const PageTakeover = React.forwardRef(
  (
    {
      onMinimize,
      onMinimizeComplete,
      onMaximize,
      onMaximizeComplete,
      takeoverSelector,
      className,
      children,
      ...rest
    }: PageTakeoverProps,
    ref
  ) => {
    type PartialRect = { width: number | undefined; height: number | undefined }

    const elementRef = React.useRef<HTMLElement>(null)
    const inlineRef = React.useRef<HTMLDivElement>(null)
    const inlineSizeRef = React.useRef<PartialRect | null>(null)
    const fullPageRef = React.useRef<HTMLElement>(null)
    const portalNode = React.useMemo(() => createHtmlPortalNode(), [])

    // Expanded state that gets set at the end of state transitions.
    const [expanded, setExpanded] = React.useState(false)
    // Expanded state that gets set at the start of state transitions.
    const [preExpanded, setPreExpanded] = React.useState(false)
    const [globalMode, setGlobalMode] = React.useState(false)

    const onExpandComplete = (event: any) => {
      if (elementRef.current && event.propertyName === 'height') {
        const element = elementRef.current

        element.removeEventListener('transitionend', onExpandComplete)

        setExpanded(true)
        if (onMaximizeComplete) setTimeout(onMaximizeComplete)
      }
    }

    // Finish the animation into the collapsed state.
    const onCollapseComplete = (event: any) => {
      if (elementRef.current && event.propertyName === 'height') {
        const element = elementRef.current

        element.removeEventListener('transitionend', onCollapseComplete)

        requestAnimationFrame(() => {
          element.style.width = '100%'
          element.style.height = '100%'
          element.style.top = '0'
          element.style.left = '0'
          element.style.position = 'relative'

          // Reset the inline element's dimensions to their previous state.
          inlineRef.current!.style.width = String(inlineSizeRef.current!.width)
          inlineRef.current!.style.height = String(
            inlineSizeRef.current!.height
          )
          inlineSizeRef.current = null

          ReactDOM.unstable_batchedUpdates(() => {
            setGlobalMode(false)
            setExpanded(false)
          })
          if (onMinimizeComplete) setTimeout(onMinimizeComplete)
        })
      }
    }

    // Toggle the globalMode state
    const onToggleExpand = () => {
      if (inlineRef.current && elementRef.current) {
        // TODO Handle SVGs using getBBox?
        const fullPageBounds = fullPageRef.current!.getBoundingClientRect()
        const inlineBounds = inlineRef.current.getBoundingClientRect()
        const element = elementRef.current
        const px = (v: number) => `${v}px`

        if (globalMode) {
          // Listen for animation end and then rerender
          // in the inline node.
          element.addEventListener('transitionend', onCollapseComplete)
        } else {
          // Store the element's current width/height settings (if any)
          // so they can be restored later.
          inlineSizeRef.current = {
            width: Number(inlineRef.current?.style.width) || undefined,
            height: Number(inlineRef.current?.style.height) || undefined,
          }
          element.addEventListener('transitionend', onExpandComplete)
        }

        const top = inlineBounds.top - fullPageBounds.top
        const left = inlineBounds.left - fullPageBounds.left

        // Ensure the inline element remains the same size.
        inlineRef.current.style.width = px(inlineBounds.width)
        inlineRef.current.style.height = px(inlineBounds.height)

        // Immediately set the dimensions to match the inline size.
        element.style.width = px(inlineBounds.width)
        element.style.height = px(inlineBounds.height)
        element.style.top = px(top)
        element.style.left = px(left)
        element.style.position = 'absolute'

        // Toggle the pre-expanded state to indicate that we are transitioning
        // into a new state.
        setPreExpanded(!preExpanded)

        // If we are currently collapsed, then immediately switch children
        // into the global node and on the next render set the full page dimensions.
        if (!globalMode) {
          // Rerender the element in the global node
          setGlobalMode(true)

          if (onMaximize) onMaximize()

          // Use a timeout so the browsers displays with the current styles
          // before they are updated.
          setTimeout(() => {
            if (elementRef.current) {
              const element = elementRef.current
              element.style.width = '100%'
              element.style.height = '100%'
              element.style.top = '0'
              element.style.left = '0'

              // TODO To be accurate, this needs to list for transitionend.
              if (onMaximizeComplete) setTimeout(onMaximizeComplete)
            }
          })
        } else {
          if (onMinimize) onMinimize()
        }
      }
    }

    const nextProps = {
      expanded,
      preExpanded,
      onToggleExpand,
      elementRef,
    }

    return (
      <>
        <InPortal node={portalNode}>
          {React.cloneElement(children, nextProps)}
        </InPortal>
        <div
          data-testid="PageTakeover"
          className={combineClasses(styles.inlineWrapper, className)}
          ref={mergeRefs([inlineRef, ref])}
          {...rest}
        >
          {!globalMode && <OutPortal node={portalNode} {...nextProps} />}
        </div>
        <PortalLocation selector={takeoverSelector}>
          <div
            ref={fullPageRef as React.RefObject<HTMLDivElement>}
            className={combineClasses(
              styles.fullPageWrapper,
              globalMode ? 'active' : 'inactive'
            )}
          >
            {globalMode && <OutPortal node={portalNode} {...nextProps} />}
          </div>
        </PortalLocation>
      </>
    )
  }
)
