import React from 'react'
import PropTypes from 'prop-types'
import { google, outlook, office365, yahoo, ics } from 'calendar-link'
import { combineClasses } from '@thesoulfresh/utils'

import { Action } from '../buttons'
import { Select, SelectOption } from '../popovers'

import CalendarIcon from '~/assets/icons/calendar.svg?react'

import styles from './AddToCalendar.module.scss'

export function AddToCalendar({
  title,
  description,
  startDatetime,
  endDatetime,
  location,
  children = 'Add To Calendar',
  className,
  ...rest
}) {
  const start = new Date(startDatetime)
  const end = new Date(endDatetime)

  const event = {
    title,
    description,
    start,
    end,
    duration: start && end ? end.getHours() - start.getHours() : undefined,
    location,
  }

  const calendars = [
    { name: 'iCal', factory: ics },
    { name: 'Google', factory: google },
    { name: 'Outlook', factory: outlook },
    { name: 'Office 365', factory: office365 },
    { name: 'Yahoo', factory: yahoo },
  ]

  return (
    <Select
      className={styles.dropdown}
      data-testid="AddToCalendarMenu"
      options={calendars}
      content={({ name, factory }) => (
        <SelectOption href={factory(event)} blank>
          {name}
        </SelectOption>
      )}
      {...rest}
    >
      {({ className: triggerClassName, ...props }, item) => (
        <Action
          data-testid="button"
          button
          feel="link"
          className={combineClasses(className, triggerClassName)}
          icon={<CalendarIcon />}
          {...props}
          children={children}
        />
      )}
    </Select>
  )
}

AddToCalendar.propTypes = {
  /**
   * Title of the event
   */
  title: PropTypes.string.isRequired,
  /**
   * Description for the event
   */
  description: PropTypes.string.isRequired,
  /**
   * Start Date Time Object for the event
   */
  startDatetime: PropTypes.instanceOf(Date),
  /**
   * endDate Time Object for the event
   */
  endDatetime: PropTypes.instanceOf(Date),
  /**
   * State Abbr that the event will take place in.
   */
  location: PropTypes.string.isRequired,
  /**
   * Button text for the event.
   */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
}
