import React from 'react'

import { combineClasses } from '~/util'
import { Action } from '../buttons'

import styles from './Card.module.scss'

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Using this flag will remove
   *   the default background color and borders.
   */
  transparent?: boolean
  /**
   * Passing a `to` prop will make the card a link.
   */
  href?: string
}

/**
 * Use this generic card container as the base for custom cards.
 * This is a good place to put any functionality/styling that should
 * be the same across all cards.
 */
export let Card = React.memo(
  React.forwardRef<HTMLDivElement, CardProps>(
    (
      {
        className,
        transparent,
        children,
        href,
        'aria-label': ariaLabel,
        ...rest
      },
      cardRef
    ) => {
      return (
        <div
          ref={cardRef}
          data-testid="Card"
          className={combineClasses(
            styles.Card,
            className,
            transparent ? styles.transparent : null
          )}
          {...rest}
        >
          {href && (
            <Action
              data-testid="cardLink"
              className={styles.link}
              href={href}
              aria-label={ariaLabel}
              unstyled
            />
          )}
          {children}
        </div>
      )
    }
  )
)
