import { combineClasses } from '~/util'
import { OrderedList, OrderedListProps } from '../ordered-list'

import styles from './Steps.module.scss'

interface StepsProps extends OrderedListProps {}

/**
 * `<Steps>` renders an OrderedList where the bullets are connected by a line in
 * order to indicate that the list is a set of the steps that the user shall
 * take.
 *
 * @param {object} props
 * @param {string} [props.className]
 */
export function Steps({ className, ...rest }: StepsProps) {
  return (
    <OrderedList
      data-testid="Steps"
      className={combineClasses(styles.Steps, className)}
      {...rest}
    />
  )
}
