import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import useLockBodyScroll from 'react-use/lib/useLockBodyScroll'

import { combineClasses } from '~/util'
import { ActionProps, CloseAction } from '../buttons'

import styles from './Modal.module.scss'

// Setting the app element is already taken care of by
// the app but exposing it for easy use in storybook stories.
export function setAppElement(el: HTMLElement | string) {
  ReactModal.setAppElement(el)
}

export interface ModalProps
  extends React.ComponentPropsWithoutRef<typeof ReactModal> {
  'data-testid'?: string
  setIsOpen?: (isOpen: boolean) => void
  closeButtonClassName?: string
  className?: string
  overlayClassName?: string
  label?: string
  closeOnBackgroundClick?: boolean
  light?: boolean
  fullscreen?: boolean
  lockBodyScroll?: boolean
  children: React.ReactNode
  analytics?: {
    category?: ActionProps['category']
    action?: ActionProps['action']
    label?: ActionProps['label']
    value?: ActionProps['value']
  }
}

export function Modal({
  'data-testid': tid,
  testId = tid,
  isOpen,
  setIsOpen,
  onAfterOpen,
  onAfterClose,
  className,
  overlayClassName,
  closeButtonClassName,
  label,
  closeOnBackgroundClick,
  analytics = {},
  light,
  fullscreen,
  lockBodyScroll = true,
  children,
  ...rest
}: ModalProps) {
  const handleClose = () => {
    setIsOpen && setIsOpen(false)
    // For now we call this immediately but eventually it
    // should be called after animating out.
    onAfterClose && onAfterClose()
  }

  // Ensure that the background page does not scroll.
  useLockBodyScroll(lockBodyScroll && isOpen)

  return (
    <ReactModal
      testId={testId}
      className={combineClasses(
        styles.Modal,
        light === true ? styles.whiteBackGround : ''
      )}
      overlayClassName={combineClasses(
        styles.overlay,
        light === true && styles.blackText,
        fullscreen && styles.fullscreen,
        overlayClassName
      )}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={handleClose}
      contentLabel={label}
      {...rest}
    >
      <div
        data-testid="Modal"
        className={styles.backdrop}
        onClick={() => closeOnBackgroundClick && handleClose()}
      >
        {setIsOpen && (
          <CloseAction
            data-testid="closeButton"
            button
            transparent
            size="l"
            feel="link"
            className={styles.closeButton}
            onClick={handleClose}
            {...analytics}
          />
        )}
        <div className={combineClasses(styles.contentWrapper, className)}>
          {children}
        </div>
      </div>
    </ReactModal>
  )
}

Modal.propTypes = {
  /**
   * Whether or not the modal is currently open.
   * You should always render the modal and use this
   * prop to specify whether the modal is shown or not.
   * This allows for animation of the modal.
   */
  isOpen: PropTypes.bool.isRequired,
  /**
   * A function that allows the modal to set the
   * isOpen state so the modal can be closed when the
   * x button is pressed.
   */
  setIsOpen: PropTypes.func.isRequired,
  /**
   * A callback that the modal will call once the
   * modal has finished animating in. This can be
   * useful in tests.
   */
  onAfterOpen: PropTypes.func,
  /**
   * A callback that the modal will call once the
   * modal has finished animating out. This can be
   * useful in tests.
   */
  onAfterClose: PropTypes.func,
  /**
   * The name of this modal that will be read to screen readers.
   * This will be used as the aria-label of the modal.
   */
  label: PropTypes.string.isRequired,
  /**
   * Allow the modal to be closed by click on the the background
   * behind the content.
   */
  closeOnBackgroundClick: PropTypes.bool,
}
